import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from './AuthService';
import { jwtDecode } from 'jwt-decode';
import './Login.css'; // Importiere die CSS-Datei für den Login-Stil

const Login = ({ setToken }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [key, setKey] = useState(0); // Initialer Schlüsselwert

  const handleLogin = async () => {
    try {
      const response = await login(username, password);
      const token = response.token;
      const decodedToken = jwtDecode(token);
      const { role: decodedRole, vollname:decodedVollname } = decodedToken;

      localStorage.setItem('Token', token);
      localStorage.setItem('Role', decodedRole);
      localStorage.setItem('Vollname', decodedVollname);

      if (decodedRole === 10) {
        navigate('/tr/auftraege');
      } else if (decodedRole === 20) {
        navigate('/dashlay');
      } else {
        navigate('/login');
      }

      // Schlüsselwert ändern, um die Router-Komponente neu zu rendern
      setKey(prevKey => prevKey + 1);
    } catch (error) {
      setErrorMessage('Falscher Benutzername oder Passwort.');
      console.error(error.message);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2 className="login-title">Willkommen</h2>
        {errorMessage && <div className="login-error">{errorMessage}</div>}
        <form>
          <div className="form-group">
            <label htmlFor="username">Benutzername</label>
            <input
              type="text"
              className="form-control"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Passwort</label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="button" className="btn btn-primary btn-lg btn-block" onClick={handleLogin}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
