import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios'
import { Modal, Button } from 'react-bootstrap';
import useAdminCheck from '../rechte_admin';

function AuftragDetail() {
        
          useAdminCheck();
  

        const token = useState(() => localStorage.getItem('Token') || null);
        //const [role, setRole] = useState(() => localStorage.getItem('Role') || null);
        const [buttonColor, setButtonColor] = useState('danger');

        const { auftragsnummer } = useParams();
        const [trainerOptions, setTrainerOptions] = useState([]);
        const navigate = useNavigate(); // useNavigate hinzugefügt


        const [projektUmsatzsteuer, setProjektUmsatzsteuer] = useState(null);
        const [projektid, setProjektid] = useState(null);
        const [km, setKM] = useState(null);


        const [auftrag, setAuftrag] = useState(null);
        const [editFields, setEditFields] = useState({
          auftrag_auftragsnummer: '',
          datum: '',
          auftraggeber: '',
          thema: '',
          umfang: '',
          beschreibung:'',
          trainer:'',
          trainersatz:'',
          tagessatz:'',
          ausgang_r:'',
          durchfuehrung:'',

        });
      
        const [showModal, setShowModal] = useState(false);

        // Hinzufügen einer neuen Funktion zum Öffnen des Modals
        const handleShowModal = () => setShowModal(true);
      
        // Hinzufügen einer neuen Funktion zum Schließen des Modals
        const handleCloseModal = () => setShowModal(false);
      
        const handleRechnungErstellenClick = async () => {
          // Hier kannst du die Logik für die Rechnungserstellung implementieren
          console.log('Rechnung erstellen...');
          // Zum Beispiel: Führe eine API-Anfrage für die Rechnungserstellung durch
          // ...
          window.open('http://my.sevdesk.de/','Data',);

        setButtonColor('success');
        setShowModal(false);
         try {
            const response2 = await axios.get(`https://db.xocore.de/bill-go/${editFields.proj}`);
            console.log(response2)
          } catch (error) {
            console.error('Fehler beim Erfassen der Spesen:', error);
          };
        
          // Schließe das Modal nach der Verarbeitung
            // setze setShowModal auf false, um das Modal zu schließen
        };
        
        useEffect(() => {
          // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
          axios.get(`https://db.xocore.de/auftrag/${auftragsnummer}`)
            .then(response => {
              setAuftrag(response.data);
              console.log("test",response.data)
              setEditFields({
                auftrag_auftragsnummer: response.data.auftrag_auftragsnummer,
                status: response.data.auftrag_status,
                datum: response.data.auftrag_datum,
                auftraggeber: response.data.auftrag_auftraggeber,
                thema: response.data.auftrag_thema,
                beschreibung: response.data.auftrag_beschreibung,
                umfang: response.data.auftrag_umfang,
                trainer:response.data.auftrag_trainer,
                durchfuehrung:response.data.auftrag_durchfuehrung,
                trainersatz:response.data.auftrag_trainer_tagessatz,
                trainersatz_zahl:response.data.auftrag_trainer_tagessatz_zahl,
                tagessatz:response.data.auftrag_preis,
                ausgang_r:response.data.auftrag_ausgangsrechnung_rechnung,
                ausgang_z:response.data.auftrag_ausgangsrechnung_zahlung,
                eingang_z:response.data.auftrag_eingangsrechnung_zahlung,
                eingang_r:response.data.auftrag_eingangsrechnung_rechnung,
                Interne:response.data.auftrag_intern,
                Zusatzinformationen:response.data.auftrag_zusatzinfo,
                proj:response.data.auftrag_projekt,
                rechnungsnummer:response.data.invoice_number
                // Setze weitere editierbare Felder hier
              }); 
            })
            .catch(error => {
              console.error('Error fetching auftrag details:', error);
            });
        }, [auftragsnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen von auftragsnummer ausgeführt wird
        console.log('token:', token);



        useEffect(() => {
          const fetchProjektUmsatzsteuer = async () => {
            try {
              // Erste Abfrage an den Endpunkt "/Auftrag/:auftragsnummer" für die Projekt-Nummer
              const response = await axios.get(`https://db.xocore.de/auftrag/${auftragsnummer}`);
              const projektNummer = response.data.auftrag_projekt;
              setAuftrag(response.data)
              if (projektNummer) {
                // Zweite Abfrage an den Endpunkt "/Projekt/:projektnummer" für die Projekt-Umsatzsteuer
                const projektResponse = await axios.get(`https://db.xocore.de/Projekt/${projektNummer}`);
                setProjektUmsatzsteuer(projektResponse.data.projekt_umsatzsteuer);
                setProjektid(projektResponse.data.projekt_id); // Korrektur hier
                setKM(projektResponse.data.projekt_kmtyp); // Korrektur hier
                console.log(projektResponse);
              } else {
                console.error('Projekt-Nummer nicht gefunden.', auftragsnummer, projektNummer);
              }
            } catch (error) {
              console.error('Fehler beim Abrufen der Projekt-Umsatzsteuer:', error);
            }
          };
        
          if (auftragsnummer) {
            fetchProjektUmsatzsteuer();
          }
        }, [auftragsnummer]);
      











        useEffect(() => {
          // Hier sollte die entsprechende Trainer-Endpunkt-URL verwendet werden
          axios.get('https://db.xocore.de/tr')
            .then(response => {
              setTrainerOptions(response.data);
            })
            .catch(error => {
              console.error('Fehler beim Laden der Trainerdaten:', error);
            });
        }, []);

        const handleCheckboxChange = (e) => {
          // Hier aktualisieren Sie den Wert in Ihrem Zustand oder tun andere Dinge
          const { name, checked } = e.target;
          setEditFields({
            ...editFields,
            [name]: checked,
          });
        };
       
        console.log(projektid)



        if (!auftrag) {
          return <div>Loading...</div>;
          
        }

        const buttonSafe = () => {
          console.log('Details:',editFields);
          axios.put(`https://db.xocore.de/auftrag/${auftragsnummer}`, editFields)
          .then(response => {
            console.log("Order successfully placed:", response.data,editFields);
            setButtonColor('success');
            // Close the popup

          })
          .catch(error => {
            console.error("Error placing order:", error);
          });


        };
       


        const handleInputChange = (e) => {
          const { name, value } = e.target;

          const updatedValue = value.replace(',', '.');

          setEditFields({
            ...editFields,
            [name]: updatedValue,
          });

        };




        const convertISODateToInputFormat = (isoDate) => {
          if (!isoDate) return ""; // Sicherstellen, dass ein Datum vorhanden ist
        
          const datePortion = isoDate.split("T")[0];
          return datePortion;
        };
       
  return (
    <div>
        
          <div className="container">
      Auftragsansicht


      <h2>{auftragsnummer} / / {auftrag.auftrag_auftraggeber}</h2>
      <form className="row g-3">
      <div className="col-md-2">
        <label htmlFor="Auftragsnummer" className="form-label">Auftragsnummer</label>
        <input type="text" readonly className="form-control" id="Auftragsnummer" value={auftragsnummer} disabled ReadOnly/>
      </div>
      <div className="col-md-2">
        <label htmlFor="Projektnummer" className="form-label">Projektnummer</label>
        <input type="text" readonly className="form-control" id="Projektnummer" value={editFields.proj} disabled readOnly/>
      </div>
      <div className="col-md-2">
        <label htmlFor="Einsatzdatum" className="form-label">Einsatzdatum</label>
        <input
              type="date"
              className="form-control"
              id="datum"
              name="datum"
              value={convertISODateToInputFormat(editFields.datum)}
              onChange={handleInputChange}
            />      

      </div>
      <div className="col-md-6">
      <label htmlFor="Auftraggeber" className="form-label">Auftraggeber</label>
      <input
              type="text"
              className="form-control"
              id="Auftraggeber"
              name="auftraggeber"
              value={editFields.auftraggeber}
              onChange={handleInputChange}
            />      </div>
      <div className="bg-light border"></div>
      <div className="col-6">
        <label htmlFor="Thema" className="form-label">Thema</label>
        <input
              type="text"
              className="form-control"
              id="thema"
              name="thema"
              value={editFields.thema}
              onChange={handleInputChange}
            /> 
      </div>


      <div className="col-md-6">
        <label htmlFor="Beschreibung" className="form-label">Beschreibung</label>
        <input
              type="text"
              className="form-control"
              id="beschreibung"
              name="beschreibung"
              value={editFields.beschreibung}
              onChange={handleInputChange}
            />  
      </div>

      <div className="col-md-2">
        <label htmlFor="inputState" className="form-label">Umsatzsteuer</label>
        <select id="inputState" className="form-select" value={projektUmsatzsteuer} disabled readonly >
        <option value="0">Umsatzsteuerfrei</option>
          <option value="1">Umsatzsteuerpflichtig</option>
        </select>
      </div>
      <div className="col-md-2">
        <label htmlFor="Tagessatz" className="form-label">Tagessatz</label>
                <input
              type="number"
              className="form-control"
              id="tagessatz"
              name="tagessatz"
              value={editFields.tagessatz}
              onChange={handleInputChange}
            /> 
      </div>
      <div className="col-md-2">
        </div>
        <div className="col-md-2">
        <label htmlFor="Status" className="form-label">Status</label>
        <select id="status" name="status"  className="form-select" onChange={handleInputChange}   value={editFields.status} >
          <option value="offen">Offen</option>
          <option value="zugesagt">Zugesagt</option>
          <option value="durchgeführt">Durchgeführt</option>
          <option value="abrechnung">Abrechnung</option>
          <option value="historisch">Historisch</option>
          <option value="archiviert">Archiviert</option>
          <option value="löschen">Löschen</option>
        </select>
      </div>
      <div className="col-md-2">
        <label htmlFor="inputState" className="form-label">Durchführungsart</label>
        <select id="inputState" name="durchfuehrung" className="form-select" onChange={handleInputChange}  value={editFields.durchfuehrung}   >
        <option value="0">Präsenz</option>
          <option value="1">Digital</option>
        </select>
      </div>
      <div className="col-md-2">
      <label htmlFor="umfang" className="form-label">Umfang {editFields.umfang}</label>
        <select name="umfang" className="form-select" onChange={handleInputChange}  value={editFields.umfang}   >
    <option value="1">1</option>
    <option value="0.66" >0.66</option>

    <option value="0.50" >0.5</option>
    <option value="0.33">0.33</option>
    <option value="0.25">0.25</option>
        </select>
      </div>
      <div className="col-md-6">
        </div>
        <div className="bg-light border"></div>


                                                <div className="col-md-6">
                                            <label htmlFor="Trainername" className="form-label">Trainername</label>
                                            <select
                                              id="Trainername"
                                              className="form-select"
                                              value={editFields.trainer}
                                              onChange={handleInputChange}
                                              name="trainer"
                                            >
                                              {trainerOptions.map(trainer => (
                                                <option key={trainer.trainer_id} value={trainer.trainer_name}>
                                                  {trainer.trainer_name}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                          <div className="col-md-2">
        <label htmlFor="Kalkulatorischer Trainersatz" className="form-label">Kalkulatorischer Trainersatz</label>
        <input
              type="number"
              className="form-control"
              id="trainersatz"
              name="trainersatz"
              value={editFields.trainersatz}
              onChange={handleInputChange}
            /> 
      </div>
      <div className="col-md-2">
        <label htmlFor="Trainersatz" className="form-label">Trainersatz</label>
        <input
              type="number"
              className="form-control"
              id="trainersatz"
              name="trainersatz"
              value={editFields.trainersatz_zahl}
              onChange={handleInputChange}
              disabled
              /> 
      </div>
      <div className="col-md-2">
        <label htmlFor="inputState" className="form-label">Fahrtkostenregelung</label>
        <select name="km" id="km" className="form-select" value={km} disabled readonly >
        <option value="0.30">0,30 EUR</option>
          <option value="0.45">0,45 EUR</option>
          <option value="1.00">Pauschal</option>
        </select>
      </div>
      <div className="bg-light border"></div>
      <div className="col-4">
        <label htmlFor="I" className="form-label">Zusatzinformationen</label>
        <textarea
              type="textarea"
              className="form-control"
              id="Zusatzinformationen"
              name="Zusatzinformationen"
              value={editFields.Zusatzinformationen}
              onChange={handleInputChange}
            /> 
      </div>
      <div className="col-4">
        <label htmlFor="I" className="form-label">Interne Bemerkungen</label>
        <textarea
              type="textarea"
              className="form-control"
              id="Interne"
              name="Interne"
              value={editFields.Interne}
              onChange={handleInputChange}
            /> 
      
      </div>
      <div className="col-2">
        <h5>Ausgangsrechnung</h5>
      <div class="form-check form-switch">
         <input className="form-check-input" type="checkbox" role="switch" name="ausgang_r" checked={editFields.ausgang_r}   id="flexSwitchCheckDefault"/>
         <label className="form-check-label" for="flexSwitchCheckDefault">Versand </label>     
         </div>
         <div class="form-check form-switch">
         <input className="form-check-input" type="checkbox" role="switch" name="ausgang_z" checked={editFields.ausgang_z}   id="flexSwitchCheckDefault"/>
         <label className="form-check-label" for="flexSwitchCheckDefault">Zahlung</label>     
         <div className="col-md-12 w-100">

        <input
              type="text"
              className="form-control"
              id="trainersatz"
              name="trainersatz"
              value={editFields.rechnungsnummer}
              disabled
            /> 
      </div>
         </div>        <div className="bg-light border"></div>

         </div> <div className="col-2">
         <h5>Eingangsrechnung</h5>
         <div class="form-check form-switch">
         <input className="form-check-input" type="checkbox" role="switch" name="eingang_r" checked={editFields.eingang_r}  onChange={handleCheckboxChange}id="flexSwitchCheckDefault"/>
         <label className="form-check-label" for="flexSwitchCheckDefault">Versand </label>     
         </div>
         <div class="form-check form-switch">
         <input className="form-check-input" type="checkbox" role="switch" name="eingang_z" checked={editFields.eingang_z} onChange={handleCheckboxChange} id="flexSwitchCheckDefault"/>
         <label className="form-check-label" for="flexSwitchCheckDefault">Zahlung</label>     
         <div className="col-md-12 w-100">        <input
              type="text"
              className="form-control"
              id="trainersatz"
              name="trainersatz"
              value=""
              disabled
            /> </div>
         </div>  <div className="bg-light border"></div>
         <div className="col-8"></div>

     
</div>


      <div className="col-8">
      <Link to={/auftraege/}>   <button onClick={buttonSafe} type="submit"  className={`btn btn-${buttonColor}`}>Speichern</button>   </Link>      <Link to={`/projekt/${editFields.proj}`}>   <button type="submit"  className={`btn btn-${buttonColor}`}>Zum Projekt</button>   </Link>
              </div>                                                                                                                                          
              
      <div className="col-2">
      <Link to={/checkliste/}>   <button type="submit" className={`btn btn-${buttonColor}`} onClick={handleShowModal}>Rechnung erstellen</button>   </Link>  
              </div>
              <div className="col-2">
      <Link to={/checkliste/}>   <button type="submit" className="btn btn-secondary">Rechnung einsehen</button>   </Link>  
              </div>
              
              <div class="d-grid gap-2">      <Link to="#" className="btn btn-danger" onClick={() => navigate(-1)}>Zurück</Link>
              
              </div>
    </form>
    </div>
    <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Rechnung erstellen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Bitte wählen Sie die Abrechnungsart:</p>
          {/* Hier kannst du weitere Inhalte des Modals hinzufügen */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary" style={{ width: '45%', marginRight: '5px' }} onClick={handleCloseModal}>
            Einzelabrechnung
          </Button>
          <Button variant="danger" style={{ width: '45%', marginRight: '5px' }} onClick={handleRechnungErstellenClick}>

            Sammelabrechnung
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AuftragDetail;