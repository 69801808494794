import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../navbar";
import Termin from "../termin";
import Accordion from 'react-bootstrap/Accordion';
import Material from "../material";
import CustomAlert from "../customAlert";
import ProjektAuftrag from "../projekt_auftrag_element";
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import FileUpload from "../FileUpload";
import FileElement from "../file-element";
import useAdminCheck from "../rechte_admin";
import EmailForm from "../e-mail-service";
import TrainerQualifcationElement from "./qualification_element";
function TrainerProfil() {

  useAdminCheck();
  const { trainerId } = useParams();
   console.log('das ist die ', trainerId)
  const { projektnummer } = useParams();
  const [auftrage, setAuftrage] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [projekt, setProjekt] = useState(null);
  const[ap, setAP] =useState(null);
  const [rap,setRAP]=useState(null);
  const[material,setMaterial]= useState([]);
  const [files, setFiles] = useState([]);
  const [buttonColor, setButtonColor] = useState('danger');

  const[Editschutz, setEditschutz]=useState('');



  const [showEmailModal, setShowEmailModal] = useState(false);

  const handleShowEmailModal = () => setShowEmailModal(true);
  const handleCloseEmailModal = () => setShowEmailModal(false);




  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const handleAlertClose = () => {
    setShowAlert(false);
    setAlertType("");
    setAlertMessage("");
  };


  let editableDataInitialState = {
    trainer_id:"",
    trainer_vorname:"", 
    trainer_nachname:"", 
    trainer_strasse: "", 
    trainer_plz:"", 
    trainer_ort:"", 
    trainer_telefonnummer:"", 
    trainer_mail:"", 
    trainer_umsatzsteuerkennzeichen:"", 
    trainer_umsatzsteuernummer:"", 
    trainer_steuernummer:"", 

  };

  const [materialList, setMaterialList] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [quantity, setQuantity] = useState("");
  const [newQualification, setNewQualification] = useState({
    name: '',
    rating: 1,
    rank: ''
  });

  const handleNewQualificationChange = (e) => {
    const { name, value } = e.target;
    setNewQualification(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleMaterialClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  


  const handleAddOrder = () => {
    const trainerId = editableData.trainer_id;
    const qualificationData = {
      name: newQualification.name,
      rating: newQualification.rating,
      rank: newQualification.rank
    };

    axios.post(`https://db.xocore.de/db/trainer/concept/${trainerId}`, qualificationData)
      .then(response => {
        console.log("Qualification successfully added:", response.data);
        setShowModal(false);
        // Optional: Reload the qualifications list to reflect the new addition
        axios.get(`https://db.xocore.de/db/trainer/concept/${trainerId}`)
          .then(response => {
            setQualification(response.data);
          })
          .catch(error => {
            console.error("Error fetching qualifications:", error);
          });
      })
      .catch(error => {
        console.error("Error adding qualification:", error);
      });
  };

  useEffect(() => {
    // Lade Materialien beim Mounten der Komponente
    axios.get("https://db.xocore.de/mt-list")
      .then(response => {
        setMaterialList(response.data);
      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Materialien:", error);
      });
  }, []);

  
  useEffect(() => {
    // Lade Materialien beim Mounten der Komponente
    axios.get(`https://db.xocore.de/db/trainer/concept/${trainerId}`)
      .then(response => {
        console.log("API response for qualifications:", response.data);

        setQualification(Array.isArray(response.data) ? response.data : [response.data]);
      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Materialien:", error);
      });
  }, []);




  const [editableData, setEditableData] = useState(editableDataInitialState);
  useEffect(() => {
    // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
    axios.get(`https://db.xocore.de/db/trainer/${trainerId}`)
      .then(response => {
        setProjekt(response.data);
        console.log('Projekt-Daten:', response.data);


        if(response.data.projekt_ersterechnung ===1){
          setEditschutz(true);
        }

        // Hier editableData aktualisieren
        setEditableData(prevData => ({
          ...prevData,
          trainerid_id: response.data.trainer_id,
          trainer_vorname: response.data.trainer_vorname,
          trainer_nachname: response.data.trainer_nachname,
          trainer_strasse: response.data.trainer_straße,
          trainer_plz:response.data.trainer_plz,
          trainer_ort:response.data.Wohnort,
          trainer_telefonnummer:response.data.trainer_telefonnummer,
          trainer_mail:response.data.trainer_mail,
          trainer_umsatzsteuerkennzeichen:response.data.Umsatzsteuerregel,
          trainer_umsatzsteuernummer:response.data.Umsatzsteuernummer,
          trainer_steuernummer:response.data.Steuernummer,
          trainer_tagesanzahl:response.data.trainer_tagesanzahl,
          trainer_trainingstage:response.data.trainer_trainingstage,

        }));
      })
      .catch(error => {
        console.error('Error fetching auftrag details:', error);
      });
}, [projektnummer]);

console.log(editableData.projekt_km)
  
  useEffect(() => {
    // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
    axios.get(`https://db.xocore.de/db/trainer/${trainerId}`)
      .then(response => {
        setProjekt(response.data);
        console.log('Projekt-Daten:', response.data);
      })
      .catch(error => {
        console.error('Error fetching auftrag details:', error);
      });
  }, [projektnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen von auftragsnummer ausgeführt wird



  useEffect(() => {
    // Daten für die spezifische Projektnummer abrufen
    axios.get(`https://db.xocore.de/projekt/1/auftraege`)
      .then(response => {
        setAuftrage(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [projektnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen der Projektnummer ausgeführt wird

  const filteredAuftrage = auftrage.filter(auftrag => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      auftrag.auftrag_thema?.toLowerCase().includes(lowerCaseSearchTerm) ||
      auftrag.auftrag_trainer?.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  

  useEffect(() => {
    // Lade Projektdaten beim Mounten der Komponente
    axios.get(`https://db.xocore.de/mt/1`)
      .then(response => {
        setMaterial(response.data);
        console.log('Material-Daten:', response.data);
      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Projektdaten:", error);
      });
  }, []);



  let defaultRecipient= editableData.ansprechpartner_mail;
  let defaultSubject= "Fragen zum Seminar/Coaching";
  let defaultText= "Folgende Materialen werden benötigt bzw. Informationen haben wir für Sie: 3 Metaplanwände; 2 Flipständer; Beamer; Flippapier; Metaplankarten; ausreichend Stifte; Der Trainer benötigt kein Hotel.";

  
  if (!projekt) {
    return <div>Loading...</div>;
  }





  
// Event-Handler für Änderungen an den Formularfeldern
const handleInputChange = (e) => {
  const { name, value } = e.target;
  setEditableData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};


  const handleSpeichernClick = () => {
    // Hier kannst du die zu speichernden Daten aus den State-Variablen abrufen

    setAlertType("success");
    setAlertMessage("Erfolgreich gespeichert!");
    setShowAlert(true);

    // Erstelle ein Objekt mit den zu aktualisierenden Daten
    const updatedData = {
      trainer_mail:editableData.trainer_mail,
      trainer_vorname: editableData.trainer_vorname,
      trainer_nachname: editableData.trainer_nachname,
      trainer_strasse: editableData.trainer_strasse,
      trainer_plz:editableData.trainer_plz,
      trainer_Wohnort:editableData.trainer_ort,
      trainer_telefonnummer:editableData.trainer_telefonnummer,
      Umsatzsteuerregel:editableData.trainer_umsatzsteuerkennzeichen,
      Umsatzsteuernummer:editableData.trainer_umsatzsteuernummer,
      Steuernummer:editableData.trainer_steuernummer,
      trainer_tagesanzahl:editableData.trainer_tagesanzahl,
      trainer_trainingstage:editableData.trainer_trainingstage,
    };

    console.log('Daten der Aktualisierung:',updatedData );
    // Sende die Anfrage an den Server, um die Daten zu aktualisieren
    axios.put(`https://db.xocore.de/db/trainer/${trainerId}`, updatedData)
      .then(response => {
        console.log('Daten erfolgreich aktualisiert:', response.data);
        // Optional: Aktualisiere den State, um die Änderungen sofort auf der Oberfläche anzuzeigen
        setProjekt(response.data);

        setAlertType("success");
        setAlertMessage("Erfolgreich gespeichert!");
        setShowAlert(true);
    
      })
      .catch(error => {
        console.error('Fehler beim Aktualisieren der Daten:', error);
      });


  };

  if (Array.isArray(material)) {
    // Hier kannst du die map-Funktion sicher verwenden
    material.map(item => console.log(item));
  } else {
    console.error("material ist kein Array:", material);
  }
  




  return (
    <Fragment>

      <CustomAlert
        message={alertMessage}
        type={alertType}
        onClose={handleAlertClose}
      />



      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6">
            <h3>{projektnummer}// Trainerprofil </h3>
          </div>
          <div className="col-md-6">
          <button type="button" className={`btn btn-${buttonColor} mb-2 w-100`}  onClick={handleSpeichernClick}>
                Speichern
              </button>

    
              ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {/* Hier kannst du den Inhalt für die Projektansicht hinzufügen */}
            <form className="row g-3">

      <div className="col-md-2">
        <label htmlFor="Projektnummer" className="form-label">No.</label>
        <input type="text" onChange={handleInputChange} className="form-control" id="Projektnummer" value={projekt.trainer_id} disabled />
      </div>

      <div className="col-md-10">
      <label htmlFor="trainer_mail" className="form-label">Mailadresse/Benutzer</label>
        <input type="text" className="form-control" name="trainer_mail" value={projekt.trainer_mail}   onChange={(e) => {const updatedProjekt = { ...projekt, trainer_mail: e.target.value };
        setProjekt(updatedProjekt); }} disabled/>
      </div>

      <div className="col-md-6">
        <label htmlFor="trainer_vorname" className="form-label">Vorname</label>
        <input type="text" onChange={handleInputChange} name="trainer_vorname"  className="form-control" id="trainer_vorname" value={editableData.trainer_vorname} />
      </div>
      <div className="col-md-6">
        <label htmlFor="trainer_nachname" className="form-label">Nachname</label>
        <input type="text"  className="form-control" name="trainer_nachname" value={editableData.trainer_nachname} onChange={handleInputChange}/>
      </div>
      <div className="col-md-1">
</div>
      <div className="col-md-6">
        <label htmlFor="trainer_strasse" className="form-label">Straße Hausnummer</label>
        <input type="text" onChange={handleInputChange} name="trainer_strasse"  className="form-control" id="trainer_strasse" value={editableData.trainer_strasse} />
      </div>
      <div className="col-md-5">
      </div>
      <div className="col-md-1">
      </div>
      
      <div className="col-md-2">
        <label htmlFor="trainer_plz" className="form-label">PLZ</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="trainer_plz" value={editableData.trainer_plz}/>
      </div>
      <div className="col-md-4">
        <label htmlFor="trainer_ort" className="form-label">Ort</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="trainer_ort" value={editableData.trainer_ort}/>
      </div>
      <div className="col-md-4">
       </div>

      <div className="col-md-4">
      <label htmlFor="Tagessatz" className="form-label">Kapazität</label>
      <input type="text" onChange={handleInputChange} className="form-control" name="projekt_tagessatz" value={editableData.projekt_tagessatz}/>

      </div>
      <div className="col-md-4">
        <label htmlFor="trainer_tagesanzahl" className="form-label"> Trainerangabe Tage </label>
      <input type="text" onChange={handleInputChange} className="form-control" name="trainer_tagesanzahl" value={editableData.trainer_tagesanzahl}/>

     </div>
        <div className="col-md-4">
        <label htmlFor="trainer_trainingstage" className="form-label">Geplante Trainertage</label>
        <input type="text" className="form-control" name="trainer_trainingstage" value={editableData.trainer_trainingstage}disabled/>

      </div>
      <div className="bg-light border"></div>
      <div className="bg-light border"></div>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Kontaktdaten {projekt.projekt_ansprechpartner}</Accordion.Header>
        <Accordion.Body>
      <div className="col-md-12">
        <label htmlFor="trainer_telefonnummer" className="form-label">Telefon</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="trainer_telefonnummer" value={editableData.trainer_telefonnummer}  />
      </div>

      <div className="col-md-12">
        <label htmlFor="trainer_mail" className="form-label">Mailadresse</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="trainer_mail" value={editableData.trainer_mail}/>
      </div>
           </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Rechnungsdaten</Accordion.Header>
        <Accordion.Body>

        <div className="col-md-12">
          <label htmlFor="Einsatzdatum" className="form-label">Geburtsdatum</label>
          <input type="date" className="form-control" name="Einsatzdatum" value="01.01.1990" onChange={handleInputChange} required/>
        </div>
      <div className="col-md-12">
        <label htmlFor="Ansprechpartner" className="form-label">Steuernummer</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="trainer_steuernummer" value={editableData.trainer_steuernummer} />
      </div>
      <div className="col-md-12">
        <label htmlFor="Mail" className="form-label">Umsatzsteuerregel</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="trainer_umsatzsteuerkennzeichen" value={editableData.trainer_umsatzsteuerkennzeichen} />
      </div>
      <div className="col-md-12">
        <label htmlFor="Straße" className="form-label">Umsatzsteuernummer</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="trainer_umsatzsteuernummer" value={editableData.trainer_umsatzsteuernummer} />
      </div>

      </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Dateien/Vita</Accordion.Header>
                <Accordion.Body>
                  
                    <FileUpload projektnummer={projektnummer}/>

        <div className="list-group">
            {files.map(file => (
              <FileElement key={file.files_id} name={file.files_name} link={file.files_url}/>
            ))}
        

      

                 
            </div>  
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      </form>
            
          </div>
      {/* Modal for adding material */}
      <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Neue Qualifikation hinzufügen</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div className="mb-3">
                    <label htmlFor="qualificationName" className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="qualificationName"
                      name="name"
                      value={newQualification.name}
                      onChange={handleNewQualificationChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="qualificationRating" className="form-label">Bewertung</label>
                    <input
                      type="number"
                      className="form-control"
                      id="qualificationRating"
                      name="rating"
                      min="1"
                      max="5"
                      value={newQualification.rating}
                      onChange={handleNewQualificationChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="qualificationRank" className="form-label">Rang</label>
                    <input
                      type="text"
                      className="form-control"
                      id="qualificationRank"
                      name="rank"
                      value={newQualification.rank}
                      onChange={handleNewQualificationChange}
                    />
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Abbrechen
                </Button>
                <Button variant="primary" onClick={handleAddOrder}>
                  Hinzufügen
                </Button>
              </Modal.Footer>
            </Modal>
          <div className="col-md-6">
          <div className="list-group">
        <button type="button" onClick={handleMaterialClick} className="btn btn-secondary mb-2 w-100">
                Konzept hinzufügen
              </button>


{Array.isArray(qualification) ? (
  qualification.map(qualificationItem => (
    <Fragment key={qualificationItem.concept_id}>
      <TrainerQualifcationElement
        konzept={qualificationItem.concept_name}
        qualistufe={qualificationItem.concept_rank}
        rating={qualificationItem.concept_rating}
      />
    </Fragment>
  ))
) : (
  <div>Keine Qualifikationen verfügbar</div>
)}
            </div>  
          </div>
        </div>
      </div>


    </Fragment>
  );
}


export default TrainerProfil;
