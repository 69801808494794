import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "./navbar";
import Termin from "./termin";
import Accordion from 'react-bootstrap/Accordion';
import Material from "./material";
import CustomAlert from "./customAlert";
import ProjektAuftrag from "./projekt_auftrag_element";
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import FileUpload from "./FileUpload";
import FileElement from "./file-element";
import useAdminCheck from "./rechte_admin";
import NewAnsprechpartnerModal from "./av/NeuerAnsprechpartner"; 
import { Tooltip as ReactTooltip } from 'react-tooltip';
import AnsprechpartnerAutoSuggest from "./ansprechpartner_autosuggest";
import RechnungsempfängerAutoSuggest from './rechnungsempfaenger_autosuggest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';



function ProjektCreate() {
 
  useAdminCheck();
  const { projektnummer } = useParams();
  const [auftrage, setAuftrage] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [projekt, setProjekt] = useState(null);
  const[ap, setAP] =useState(null);
  const [rap,setRAP]=useState(null);
  const[material,setMaterial]= useState([]);
  const [files, setFiles] = useState([]);
  const [buttonColor, setButtonColor] = useState('danger');


// Zustand für den ausgewählten Ansprechpartner
const [selectedAnsprechpartner, setSelectedAnsprechpartner] = useState(null);
const [selectedRechnungsempfänger, setSelectedRechnungsempfänger] = useState(null);

// Callback-Funktion, um den ausgewählten Ansprechpartner zu setzen
const handleSelectedAnsprechpartner = (ansprechpartner) => {
  setSelectedAnsprechpartner(ansprechpartner);
  const selectedAnsprechpartnerId = ansprechpartner ? ansprechpartner.ansprechpartner_id : null;
  setFormFields(prevState => ({
    ...prevState,
    projekt_ansprechpartner: selectedAnsprechpartnerId, // Hier wird der ausgewählte Ansprechpartner gesetzt
  }));
  console.log('Ausgewählte Ansprechpartner Id:', selectedAnsprechpartnerId);
};
const handleRechnungsempfängerSelected = (rechnungsempfänger) => {
    // Setzen Sie den ausgewählten Rechnungsempfänger im Zustand
    setSelectedRechnungsempfänger(rechnungsempfänger);
    const selectedRechnungId = rechnungsempfänger ? rechnungsempfänger.ansprechpartner_id : null;
    setFormFields(prevState => ({
      ...prevState,
      projekt_rechnungsempfaenger: selectedRechnungId, // Hier wird der ausgewählte Ansprechpartner gesetzt
    }));
    console.log('Ausgewählte Rechnung Id:', selectedRechnungId);
  };

  //Statusmeldungen für den Ansprechpartner
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);



  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");


  const [materialList, setMaterialList] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [quantity, setQuantity] = useState("");

  const [formFields, setFormFields] = useState({
    auftraggeber: '',
    ansprechpartner: '',
    strasse: '',
    plz: '',
    ort: '',
    email: '',
    telefon: '',
    kontakttyp: '',
    km:'0.45',
    umsatzsteuer:'1',
    status:'zugesagt',
    umfang:'',
    projekt_ma:'Dennis',
  });
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormFields(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  
// Event-Handler für Änderungen an den Formularfeldern
const handleInputChange = (e) => {
  const { name, value } = e.target;
  const sanitizedValue = value.replace(',', '.');
  setFormFields(prevState => ({
    ...prevState,
    [name]: sanitizedValue,
  }));
};



  const handleSpeichernClick = (e) => {
      e.preventDefault();
      // Daten an die Schnittstelle senden
      console.log(formFields)
      axios.post(`https://db.xocore.de/projekt/`, formFields)
        .then(response => {
          console.log('Projekt erfolgreich erstellt:', response.data);
          // Hier können Sie je nach Bedarf eine Benachrichtigung anzeigen oder zur nächsten Seite navigieren
          // navigate('/home');
          setAlertType("success");
          setAlertMessage("Erfolgreich gespeichert!");
          setShowAlert(true);
         // After showing the alert, wait for 3 seconds and then navigate
         setTimeout(() => {
          // Replace 'newProjektId' with the actual key to access the new project ID
          // Use the `response` object to access the newly created project ID
          const newProjektId = response.data.newProjektId;
          // Navigate to the internal page using the project ID
          // Replace '/projekt/' with your actual internal page route
          window.location.href = `/projekt/${newProjektId}`;
        }, 3000); // 3000 milliseconds = 3 seconds
      })
        .catch(error => {
          console.error('Fehler beim Erstellen des Projekts:', error);
  
        });
    };

 
  if (Array.isArray(material)) {
    // Hier kannst du die map-Funktion sicher verwenden
    material.map(item => console.log(item));
  } else {
    console.error("material ist kein Array:", material);
  };
  



  return (
    <Fragment>




      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6">
            <h3>{projektnummer}// Neues Projekt anlegen </h3>
          </div>
          <div className="col-md-6">
          <button type="button" className={`btn btn-${buttonColor} mb-2 w-100`}  onClick={handleSpeichernClick}>
                Speichern
              </button>


              ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            {/* <input
              type="text"
              className="form-control"
              placeholder="Aufträge durchsuchen..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            /> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {/* Hier kannst du den Inhalt für die Projektansicht hinzufügen */}
            <form className="row g-3">

      <div className="col-md-2">
        <label htmlFor="Projektnummer" className="form-label">No.</label>
        <input type="text" onChange={handleInputChange} className="form-control" id="Projektnummer" value="0" disabled />
      </div>

      <div className="col-md-10">
      <label htmlFor="auftraggeber" className="form-label">Auftraggeber</label>
        <input type="text" className="form-control" name="auftraggeber" value={formFields.auftraggeber}   onChange={handleInputChange}></input>
      </div>

      <div className="col-md-6">
        <label htmlFor="Thema" className="form-label">Thema</label>
        <input type="text" onChange={handleInputChange} name="projekt_thema"  className="form-control" id="Thema" value={formFields.thema}  />
      </div>
      <div className="col-md-6">
        <label htmlFor="Beschreibung" className="form-label">Beschreibung</label>
        <input type="text"  className="form-control" name="projekt_beschreibung" value={formFields.beschreibung} onChange={handleInputChange}/>
      </div>

      <div className="col-md-2">
        <label htmlFor="Umfang" className="form-label">Umfang</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="umfang" value={formFields.umfang}/>
      </div>
      <div className="col-md-4">
        <label htmlFor="Tagessatz" className="form-label">Tagessatz</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="projekt_tagessatz" value={formFields.tagessatz}/>
      </div>
      <div className="col-md-6">
        <label htmlFor="Umsatzsteuer" className="form-label" >Umsatzsteuer</label>
        <select id="projekt_umsatzsteuer" name="projekt_umsatzsteuer" className="form-select"  onChange={handleInputChange} value={formFields.umsatzsteuer}  >
        <option value="0">Umsatzsteuerfrei</option>
          <option value="1">Umsatzsteuerpflichtig</option>
        </select>
      </div>
      <div className="col-md-12s">
        <label htmlFor="Status" className="form-label">Status</label>
        <select id="projekt_status" name="projekt_status"  className="form-select" onChange={handleInputChange}   value={formFields.status} >
          <option value="offen">Offen</option>
          <option value="zugesagt">Zugesagt</option>
        </select>
      </div>
      <div className="col-md-4">
        <label htmlFor="Status" className="form-label">Mitarbeiter</label>
        <select id="projekt_ma" name="projekt_ma" onChange={handleInputChange} value={formFields.projekt_ma} className="form-select"    >
        <option value="Peter">Peter</option>
          <option value="Dennis">Dennis</option>
          <option value="Monika">Monika</option>
          <option value="Wolfgang">Wolfgang</option>
          <option value="Sandro">Sandro</option>
        </select>
      </div>
      <div className="col-md-4">
        <label htmlFor="Wegstreckenentschädigung" className="form-label">KM-Abrechnung </label>
        <select id="km" className="form-select" name="km" value={formFields.km}  onChange={handleInputChange}  required  >
        <option value="0.45">0,45 EUR</option>
        <option value="0.30">0,30 EUR</option>

          <option value="1">Pauschal</option>
        </select>      </div>
      <div className="bg-light border"></div>
      <div className="bg-light border"></div>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Fachlicher Ansprechpartner </Accordion.Header>
        <Accordion.Body>
        <button  type="button" onClick={handleModalShow} className="btn btn-danger mb-2 w-100">Neuer Ansprechpartner</button>
        <FontAwesomeIcon icon={faInfoCircle} data-tooltip-content="Wirklich nur neue Ansprechpartner anlegen." data-tooltip-id="my-tooltip" data-placement="top" data-bs-toggle="tooltip"  />
        <ReactTooltip id="my-tooltip" place="top" effect="solid"></ReactTooltip>
        <AnsprechpartnerAutoSuggest onAnsprechpartnerSelected={handleSelectedAnsprechpartner} />

      </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Rechnungsempfänger</Accordion.Header>
        <Accordion.Body>
        <button  type="button" onClick={handleModalShow} className="btn btn-danger mb-2 w-100">Neuer Ansprechpartner</button>
        <FontAwesomeIcon icon={faInfoCircle} data-tooltip-content="Wirklich nur neue Ansprechpartner anlegen." data-tooltip-id="my-tooltip" data-placement="top" data-bs-toggle="tooltip"  />
        <ReactTooltip id="my-tooltip" place="top" effect="solid"></ReactTooltip>
      
          <label htmlFor="Ansprechpartner" className="form-label">Rechnungsempfänger</label>
          <RechnungsempfängerAutoSuggest onRechnungsempfängerSelected={handleRechnungsempfängerSelected} />
       
      </Accordion.Body>
      </Accordion.Item>


      </Accordion>
      </form>
            
          </div>
 
          <div className="col-md-6">
          
            <div className="list-group">

            </div>
          </div>
        </div>
 
      
      </div>

      <NewAnsprechpartnerModal show={showModal} handleClose={handleModalClose} />
    </Fragment>
  );
}

export default ProjektCreate;
