import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';

const styles = {
  container: {
    textAlign: 'center',
    margin: '20px',
  },
  dropzone: {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '40px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filePreview: {
    marginTop: '20px',
  },
  button: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  icon: {
    fontSize: '48px',
    marginBottom: '10px',
  },
  popup: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffffff',
    padding: '20px',
    border: '1px solid #cccccc',
    borderRadius: '4px',
    zIndex: '9999',
  },
  loader: {
    borderTop: '16px solid #f3f3f3',
    borderRight: '16px solid #3498db',
    borderBottom: '16px solid #f3f3f3',
    borderLeft: '16px solid #3498db',
    borderRadius: '50%',
    width: '120px',
    height: '120px',
    animation: 'spin 2s linear infinite',
    margin: 'auto',
  },
};

const SpesenErfassen = () => {
  const { auftragsnummer } = useParams();

  const [editFields, setEditFields] = useState({
    Spesenart: '',
    Betrag: '',
    Menge: '',
    file: null,
    Trainer: null,
  });
  const [uploading, setUploading] = useState(false);
  const [projektUmsatzsteuer, setProjektUmsatzsteuer] = useState(null);
  const [projektid, setProjektid] = useState(null);
  const [km, setKM] = useState('1');
  const [projekt, setProjekt] = useState('1');
  const [Trainer, setTrainer] =useState('');

  const navigate = useNavigate(); // useNavigate hinzugefügt
  const [showPopup, setShowPopup] = useState(false); // State variable for popup


  useEffect(() => {
    const fetchProjektUmsatzsteuer = async () => {
      try {
        
        // Erste Abfrage an den Endpunkt "/Auftrag/:auftragsnummer" für die Projekt-Nummer
        const auftragResponse = await axios.get(`https://db.xocore.de/auftrag/${auftragsnummer}`);
        const projektNummer = auftragResponse.data.auftrag_projekt;
        setTrainer(auftragResponse.data.auftrag_trainer)
        console.log('trainer lautet', Trainer);
        if (projektNummer) {
          // Zweite Abfrage an den Endpunkt "/Projekt/:projektnummer" für die Projekt-Umsatzsteuer
          const projektResponse = await axios.get(`https://db.xocore.de/projekt/${projektNummer}`);
          setProjektUmsatzsteuer(projektResponse.data.projekt_umsatzsteuer);
          setProjektid(projektResponse.data.projekt_id); // Korrektur hier
          setKM(projektResponse.data.projekt_kmtyp); // Korrektur hier
          console.log(projektResponse);
          console.log(projektUmsatzsteuer);
          
        } else {
          console.error('Projekt-Nummer nicht gefunden.', auftragsnummer, projektNummer);
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Projekt-Umsatzsteuer:', error);
      }
    };
  
    if (auftragsnummer) {
      fetchProjektUmsatzsteuer();
    }
  }, [auftragsnummer]);


  const handleSpesenErfassen = async () => {
    setUploading(true);
  
    const { Spesenart, Betrag, Menge, file } = editFields;
  
    // Überprüfung, ob Betrag leer ist
    const betragToSubmit = Betrag.trim() === '' ? km : Betrag;
  
    // Sammle die Daten aus dem Formular und der Datei
    const formData = new FormData();
    formData.append('auftragsnummer', auftragsnummer);
    formData.append('Spesenart', Spesenart);
    formData.append('Betrag', betragToSubmit);
    formData.append('Trainer', Trainer);
    formData.append('Menge', Menge);
  
    if (file) {
      if (file.type === 'application/pdf') {
        // Falls die Datei bereits ein PDF ist, füge sie direkt zum FormData hinzu
        formData.append('file', file);
      } else {
        // Erstellen eines Blob-URLs für das hochgeladene Bild
        const imgUrl = URL.createObjectURL(file);
  
        // Erstellen eines neuen PDF-Dokuments
        const pdfDoc = new jsPDF();
        pdfDoc.addImage(imgUrl, 'PNG', 0, 0, 210, 297); // Hier 'PNG' oder 'JPEG' je nach Bildformat
  
        // Speichern des PDF-Dokuments
        const pdfBlob = pdfDoc.output('blob');
        const pdfFile = new File([pdfBlob], file.name.replace(/\.[^/.]+$/, ".pdf"), { type: 'application/pdf' });
        console.log(pdfFile);
        formData.append('file', pdfFile);
      }
    }
  
    console.log(Betrag);
  
    // Sende die Formulardaten und die Datei an die Schnittstelle
    try {
      const response = await axios.post(`https://db.xocore.de/file-mt/${auftragsnummer}`, formData);
      console.log('Spesen erfasst:', response.data);
      setShowPopup(true); // Set popup state to true
      setTimeout(() => {
        setShowPopup(false);
      }, 5000);
      navigate(-1);
    } catch (error) {
      console.error('Fehler beim Erfassen der Spesen:', error);
    } finally {
      // Setze den Upload-Status auf false, wenn der Upload abgeschlossen ist, unabhängig vom Ergebnis
      setUploading(false);
    }
  };
  

  const handleFileSelect = (selectedFile) => {
    setEditFields({
      ...editFields,
      file: selectedFile,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

      // Ersetze das Komma durch einen Punkt, falls vorhanden
  const sanitizedValue = value.replace(',', '.');


    setEditFields({
      ...editFields,
      [name]: sanitizedValue,
    });
  };

  const onDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    handleFileSelect(selectedFile);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  });


  return (
    <div>
      <div className="container">
        Spesenerfassung
        <h2>{projektid}-{auftragsnummer} // Spesen </h2>
        <form className="row g-3">
          <div className="col-8">
            <label htmlFor="Spesenart" className="form-label">Spesenart</label>
            <select
              className="form-select"
              id="Spesenart"
              name="Spesenart"
              value={editFields.Spesenart}
              onChange={handleInputChange}
            >
              <option value="">Bitte wählen Sie eine Spesenart</option>
              <option value="Wegstreckenentschädigung">Wegstreckenentschädigung</option>
              <option value="Hotelkosten">Hotelkosten</option>
              <option value="Sonstige Kosten">Sonstige Kosten</option>
            </select>
          </div>
          <div className="col-8">
            <label htmlFor="Bruttobetrag" className="form-label"  style={{ display: projektUmsatzsteuer === 0 ? "block" : "none" }}>Bruttobetrag</label>
            <label htmlFor="Nettobetrag" className="form-label" style={{ display: projektUmsatzsteuer === 1 ? "block" : "none" }} >Nettobetrag</label>
            <input
              type="text"
              className="form-control"
              id="Betrag"
              name="Betrag"
              value={editFields.Betrag}
              onChange={handleInputChange}
             style= {{ display: editFields.Spesenart === "Wegstreckenentschädigung" ? "none" : "block" }}
            />
          </div>
          <div className="col-8">
            <label htmlFor="Kilometer" className="form-label" style={{ display: editFields.Spesenart === "Wegstreckenentschädigung" ? "block" : "none" }}>Kilometer</label>
            <input
              type="text"
              className="form-control"
              id="Menge"
              name="Menge"
              value={editFields.Menge}
              onChange={handleInputChange}
              style={{ display: editFields.Spesenart === "Wegstreckenentschädigung" ? "block" : "none" }}
            />
          </div>
          <div className="col-8">
            <div {...getRootProps()} style={styles.dropzone}>
              <input {...getInputProps()} />
              <FontAwesomeIcon icon={faCloudUploadAlt} style={styles.icon} />
              <p>Drag & drop eine Datei hier oder klicke, um eine hochzuladen.  </p>
            </div>
            {editFields.file && (
              <div style={styles.filePreview}>
                <p>Ausgewählte Datei: {editFields.file.name}</p>
              </div>
            )}
          </div>
          <div className="col-8">
          <button
              type="button"
              className="btn btn-secondary mb-2 w-100"
              onClick={handleSpesenErfassen}
              disabled={uploading}
            >
              {uploading ? <div style={styles.loader}></div> : 'Spesen erfassen'}
            </button>            <Link to="#" className="btn btn-danger" onClick={() => navigate(-1)}>Zurück</Link>
     </div>
        </form>
        {showPopup && ( // Render popup if showPopup state is true
        <div style={styles.popup}>
          Reisespesen erfolgreich erfasst.
        </div>
      )}
      </div>
    </div>
  );
}

export default SpesenErfassen;
