import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";  // Importiere Axios
import RechnungsterminElement from "../rechnungs_element";

function RechnungAV() {
  const [auftragsView, setAuftragsView] = useState(null);
  const [auftrage, setAuftrage] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("abrechnung"); // Abrechnung als Standard

  useEffect(() => {
    // Hier wird Axios verwendet, um Daten aus der Datenbank abzurufen
    axios.get("https://db.xocore.de/auftrag")  // Passe die URL an
      .then(response => {
        setAuftrage(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Das leere Array stellt sicher, dass useEffect nur einmal beim Laden der Komponente ausgeführt wird



  const showAuftragDetail = (auftragsid) => {
    setAuftragsView(auftragsid);
    console.log(auftragsView);
  };

  // Sortiere die auftrage-Liste nach dem auftrag_datum

  const filteredAuftrage = auftrage.filter(auftrag => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const status = auftrag.auftrag_status.toLowerCase();

    return (
        (selectedStatus === null || status === selectedStatus) &&
        (auftrag.auftrag_thema.toLowerCase().includes(lowerCaseSearchTerm) ||
        auftrag.auftrag_auftraggeber.toLowerCase().includes(lowerCaseSearchTerm))
      );
    });
    
    return (
        <Fragment>
        
    
          <div>
        <div className="col-mb-6">
          <h3> Abrechnungsübersicht</h3>
        </div>
        <div className="col-mb-6">
          <div className="status-tabs nav nav-pills">

            <span
              className={`nav-item nav-link ${selectedStatus === 'abrechnung' ? 'active' : ''}`}
              onClick={() => setSelectedStatus('abrechnung')}
            >
              Abrechnung
            </span>
            <span
              className={`nav-item nav-link ${selectedStatus === 'durchgeführt' ? 'active' : ''}`}
              onClick={() => setSelectedStatus('durchgeführt')}
            >
              Durchgeführt
            </span>
          </div>
          {/* Suchfeld direkt in der TerminListe */}
          <input
            type="text"
            className="form-control"
            placeholder="Aufträge durchsuchen..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
    
          <div className="list-group">
            {filteredAuftrage.map((auftrag) => (
              <Fragment key={auftrag.auftrag_nummer}>
                <RechnungsterminElement
                  showDetails={() => showAuftragDetail(auftrag.auftrag_nummer)}
                  datum={new Date(auftrag.auftrag_datum).toLocaleDateString('de-DE')}
                  thema={auftrag.auftrag_thema}
                  auftragsnummer={auftrag.auftrag_id}
                  status={auftrag.auftrag_status}
                  auftraggeber={auftrag.auftrag_auftraggeber}
                  eingang={auftrag.auftrag_eingangsrechnung_zahlung}
                  ausgang={auftrag.auftrag_ausgangsrechnung_zahlung}
                />
              </Fragment>
            ))}
          </div>
        </Fragment>
      );
    }
    
    export default RechnungAV;


